import React from "react";

const Ayuda = () => {
  return (
    <>
      <img src="imagenes/ayuda_mar.png" />
      <br></br>
      <img src="imagenes/ayuda_tiempo.png" />
    </>
  );
};

export default Ayuda;
