import React from "react";
import AppRutas from "./Rutas/AppRutas";
import Pie from "./Components/Pie";
import { useState } from "react";
import { UserContext } from "./Contexts/UserContext";
import { DatosLocalizacion } from "./Helpers/DatosLocalizacion";
import { Configuracion } from "./Helpers/Configuracion";
import { useMediaQuery } from "react-responsive";
// import DatosMeteogalicia from "./Components/DatosMeteogalicia";

const App = () => {
  const version = "0.7.6 menpro";

  // const isMobileDevice = useMediaQuery({
  //   query: "(min-device-width: 480px)",
  // });

  // const isTabletDevice = useMediaQuery({
  //   query: "(min-device-width: 768px)",
  // });

  // const isLaptop = useMediaQuery({
  //   query: "(min-device-width: 1024px)",
  // });

  // const isDesktop = useMediaQuery({
  //   query: "(min-device-width: 1200px)",
  // });

  // const isBigScreen = useMediaQuery({
  //   query: "(min-device-width: 1201px )",
  // });

  if (localStorage.getItem("localizacion_local") === null) {
    localStorage.setItem(
      "localizacion_local",
      JSON.stringify(DatosLocalizacion.localizacion[0])
    );
  }
  if (localStorage.getItem("configuracion_local") === null) {
    localStorage.setItem(
      "configuracion_local",
      JSON.stringify(Configuracion.configuracion[0])
    );
  }
  const local_temp = JSON.parse(localStorage.getItem("localizacion_local"));
  const configuracion_temp = JSON.parse(
    localStorage.getItem("configuracion_local")
  );

  let menu_config_temp = {
    ancho_limite: "420px",
    espacio_intermedio: "0",
    es_pequeno: false,
    pequeno_forzado: false,
  };

  const [localizacion_estado, setLocalizacionEstado] = useState(local_temp);
  const [configuracion_estado, setConfiguracionEstado] =
    useState(configuracion_temp);
  const [graficar_estado, setGraficarEstado] = useState(new Date());
  const [mareas_estado, setMareasEstado] = useState(null);
  const [menu_config_estado, setMenuConfigEstado] = useState(menu_config_temp);

  return (
    <UserContext.Provider
      value={{
        localizacion_estado: [localizacion_estado, setLocalizacionEstado],
        configuracion_estado: [configuracion_estado, setConfiguracionEstado],
        graficar_estado: [graficar_estado, setGraficarEstado],
        mareas_estado: [mareas_estado, setMareasEstado],
        menu_config_estado: [menu_config_estado, setMenuConfigEstado],
      }}
    >
      <AppRutas />

      <Pie version={version} />
    </UserContext.Provider>
  );
};

export default App;
