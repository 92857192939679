import React from "react";
import { useContext } from "react";
import { UserContext } from "../Contexts/UserContext";
import { useMediaQuery } from "react-responsive";


const Pie = (props) => {
  function borrarCache() {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }
  }

  const { localizacion_estado } = useContext(UserContext);
  const [localizacion, setLocalizacion] = localizacion_estado;

  const { graficar_estado } = useContext(UserContext);
  const [graficar, setGraficar] = graficar_estado;

  const { menu_config_estado } = useContext(UserContext);
  const [menu_config, setMenuConfig] = menu_config_estado;

  if (menu_config.es_pequeno) { menu_config.espacio_intermedio = "0%" };

  function alternaPeque() {

    let menu_config_temp = menu_config;
    if (menu_config.pequeno_forzado) {
      menu_config_temp.pequeno_forzado = false;
    } else {
      menu_config_temp.pequeno_forzado = true;

    }

    setMenuConfig(menu_config_temp);
    setGraficar(new Date());
  }

  return (
    <>
      <table className=" pie miniletra containertabla_barrainfo">
        <tr>
          <td>
            {/* {localizacion.direccion} | {localizacion.latitud.toString().slice(0, 4)} ;{" "}
      {localizacion.longitud.toString().slice(0, 4)}|  */}
            <a href="#"

              onClick={() => alternaPeque()}
            >
              La práctica's App
            </a> |
          </td>
          <td>
            <a href="/fuentes" >
              fuentes
            </a>
          </td>
          <td width={menu_config.espacio_intermedio}></td>
          <td>
            |

            <a href="#" onClick={borrarCache}>
              Ver: {props.version}
            </a>
            {" | "}

            <a href="/ayuda">
              <img src="imagenes/ayuda.png" height={"20px"} />
            </a>
          </td>
        </tr>
      </table >
    </>

  );
};

export default Pie;
